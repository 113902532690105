import React, { useEffect, useState } from 'react';

const ContactUs = () => {


  const [footerAddress, setFooterAddress] = useState([]);


  useEffect(() => {
    fetch(`https://ecommerceleadgenerationservice-4102c662a84f.herokuapp.com/footer-address`)
      .then((res) => res.json())
      .then((info) => setFooterAddress(info));
  }, [footerAddress]);


  const handleMessage = (event) => {
    event.preventDefault();
    const name = event.target.name.value;
    const email = event.target.email.value;
    const number = event.target.number.value;
    const subject = event.target.subject.value;
    const message = event.target.message.value;
    const messageStatus = event.target.messageStatus.value;

    const addItem = {
      name,
      email,
      number,
      subject,
      message,
      messageStatus,
    };

    const url = `https://ecommerceleadgenerationservice-4102c662a84f.herokuapp.com/add-message`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(addItem),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result); // Log the result from the server
        alert('Your Message is Sent Thank you !!');
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('There was an error. Please try again later.');
      });
  };

  return (


    <>
      <section className="talk-section pt-100 pb-100" id='contact-sec'>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="talk-form">
                <form onSubmit={handleMessage}>
                  <div className="form-group">
                    <input type="text" name="name" id="name" className="form-control" required data-error="Please enter your name" placeholder="What's Your Name" />
                    <div className="help-block with-errors" />
                  </div>
                  <div className="form-group">
                    <input type="text" name="number" id="number" className="form-control" required data-error="Please enter your name" placeholder="Your Phone Number" />
                    <div className="help-block with-errors" />
                  </div>
                  <div className="form-group">
                    <input type="email" name="email" id="email" className="form-control" required data-error="Please enter your email" placeholder="Your email address here" />
                    <div className="help-block with-errors" />
                  </div>
                  <div className="form-group">
                    <input type="text" name="subject" id="subject" required data-error="Please enter your subject" className="form-control" placeholder="Subject" />
                    <div className="help-block with-errors" />
                  </div>
                  <div className="form-group">
                    <input type="text" placeholder="Your Subject" name="messageStatus" value="UnRead" hidden required="required" data-error="" />
                    <div className="help-block with-errors" />
                  </div>
                  <div className="form-group">
                    <textarea name="message" className="form-control" id="message" cols={30} rows={6} required data-error="Write your message" placeholder="Write your text here" defaultValue={""} />
                    <div className="help-block with-errors" />
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <button type="submit" className="btn btn-primary">Submit Now</button>
                      <div id="msgSubmit" className="h3 text-center hidden" />
                      <div className="clearfix" />
                    </div>
                  </div>
                </form>
              </div>
              <div className="talk-image">
                <div className="talk-shape">
                  <img src="assets/img/talk/1.png" className="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="image" />
                </div>
                <div className="talk-arrow">
                  <img src="assets/img/talk/2.png" className="wow animate__animated animate__fadeInRight" data-wow-delay="0.6s" alt="image" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="talk-content-area">
                <span>Have any queries?</span>
                <h3>Let’s Talk if you want our Information</h3>
                <div className="single-footer-widget">

                  {
                    footerAddress.map(f=>  <ul className="footer-quick-links address-link">
                    <li>
                      <a href="/">
                        <i className="fa fa-map-marker" />
                        {f.Location}
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa fa-envelope" />
                        {f.footerEmail}
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa fa-phone" />
                        {f.Phone}
                      </a>
                    </li>
                  </ul> )
                  }

                 

                </div>

              </div>
            </div>
          </div>
        </div>
      </section>


    </>
  );
};

export default ContactUs;
