import React, { useEffect, useState } from "react";

const TestimonialSection = () => {
  const [testimonial, setTestimonial] = useState([]);
  const [testimonialText, setTestimonialText] = useState([]);

  useEffect(() => {
    fetch(`https://ecommerceleadgenerationservice-4102c662a84f.herokuapp.com/testimonialtext`)
      .then((res) => res.json())
      .then((info) => setTestimonialText(info));
  }, []);

  useEffect(() => {
    fetch(`https://ecommerceleadgenerationservice-4102c662a84f.herokuapp.com/testimonials`)
      .then((res) => res.json())
      .then((info) => setTestimonial(info));
  }, []);



  return (
    <>

      {
        testimonial.map(t => <section className="client-section ptb-100">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5">
                <div className="client-image">
                  <img src={t.image} alt="image" />
                  <div className="client-shape">
                    <img src="assets/img/client/2.png" alt="image" />
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="= owl-theme">
                  <div className="client-content-area">
                    <h3>{t.personName}</h3>
                    <span>{t.personTitle}</span>
                    <p>{t.desc}</p>
                    <div className="client-logo">
                      
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>)
      }


    </>
  );
};

export default TestimonialSection;
