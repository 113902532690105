import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";


const AboutSection = () => {

  const [about, setAbout] = useState([]);

  useEffect(() => {
    fetch(`https://ecommerceleadgenerationservice-4102c662a84f.herokuapp.com/about`)
      .then((res) => res.json())
      .then((info) => setAbout(info));
  }, []);





  return (

 

    <>
    {
      about.map(a=> <section className="support-section pb-100" id="about-sec">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="support-image">
              <img src={a.aboutImg} alt="image" />
              <div className="support-shape">
                <img src="assets/img/support/2.png" className="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="image" />
              </div>
              <div className="support-arrow">
                <img src="assets/img/support/3.png" className="wow animate__animated animate__fadeInRight" data-wow-delay="0.6s" alt="image" />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="support-area-content">
              <span>{a.aboutText}</span>
              <h3>{a.aboutHeading}</h3>
             
              <h5>{a.aboutPointOne}</h5>
              <p>{a.aboutPointOneDetails}</p>
              <h5>{a.aboutPointTwo}</h5>
              <p>{a.aboutPointTwoDetails}</p>
              <div className="support-btn">
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
     )
    }


     

    </>
  );
};

export default AboutSection;
