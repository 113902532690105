import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import { signOut } from "firebase/auth";



const NavBar = () => {

  const [user] = useAuthState(auth);
  const handleSignOut = () => {
    signOut(auth);
  }
  const [users, setUser] = useState([]);
  const [logo, setLogo] = useState([]);

  useEffect(() => {
    fetch(`https://ecommerceleadgenerationservice-4102c662a84f.herokuapp.com/logo`)
      .then((res) => res.json())
      .then((info) => setLogo(info));
  }, []);


  useEffect(() => {
    fetch(`https://ecommerceleadgenerationservice-4102c662a84f.herokuapp.com/users`)
      .then((res) => res.json())
      .then((info) => setUser(info));
  }, []);




  return (




   

    <>
    {
      logo.map(l=> <div className="navbar-area">
      <div className="exto-responsive-nav">
        <div className="container">
          <div className="exto-responsive-menu">
            <div className="logo">
              <a href="index.html">
                <img src={l.logo} className="main-logo" alt="logo" />
                <img src={l.logo} className="white-logo" alt="logo" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="exto-nav">
        <div className="container">
          <nav className="navbar navbar-expand-md navbar-light">
            <a className="navbar-brand" href="/">
              <img src={l.logo} className="main-logo" alt="logo" />
              <img src={l.logo} className="white-logo" alt="logo" />
            </a>
            <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
              <ul className="navbar-nav">

                <li className="nav-item">
                  <a href="/" className="nav-link">Home</a>
                </li>
                <li className="nav-item">
                  <a href="#about-sec" className="nav-link">About Us</a>
                </li>
                <li className="nav-item">
                  <a href="#services-sec" className="nav-link">Services</a>
                </li>
                <li className="nav-item">
                  <a href="#pricing-sec" className="nav-link">Pricing</a>
                </li>
                <li className="nav-item">
                  <a href="#contact-sec" className="nav-link">Contact</a>
                </li>
              </ul>
              <div className="others-options d-flex">



                {
                  user ?
                    <li className="nav-item d-flex align-items-center">
                      <Link to='/dashboard' className=" saas-btn d-flex align-items-center">
                        Dashboard
                      </Link>
                    </li>
                    :
                    <li className="nav-item d-flex align-items-center">
                      <Link to='login' className=" saas-btn d-flex align-items-center">
                        Login
                        
                      </Link>
                    </li>
                }

                {
                  users.filter(u => u.userEmail === user?.email).length === 1 &&
                  <li className="nav-item ml-3 d-flex align-items-center">
                    <Link to='/admin/' className="nav-link saas-btn d-flex align-items-center">
                      Admin
                    </Link>
                  </li>
                }





              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>)
    }
      



    </>
  );
};

export default NavBar;
