import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ServicesSection = () => {


  const [items, setItems] = useState([]);
  const [service, setService] = useState([]);

  useEffect(() => {
    fetch(`https://ecommerceleadgenerationservice-4102c662a84f.herokuapp.com/service-items`)
      .then((res) => res.json())
      .then((info) => setItems(info));
  }, []);


  useEffect(() => {
    fetch(`https://ecommerceleadgenerationservice-4102c662a84f.herokuapp.com/service`)
      .then((res) => res.json())
      .then((info) => setService(info));
  }, []);


  return (



    <>

      <section className="benefit-section pb-70" id="services-sec">
        <div className="container">
          {
            service.map(s => <div className="section-title">
              <span>{s.serviceHeading}</span>
              <h2>{s.servicesubHeading}</h2>
            </div>)
          }

          <div className="row">
            {
              items.map(e=><div className="col-lg-6 col-md-6">
              <div className="benefit-area-content">
              <img src={e.serviceIcon} alt="icon" />
                <h3>{e.serviceTitle}</h3>
                <p>{e.serviceDetails}</p>
              </div>
            </div> )
            }
            
            
            
         
          </div>
        </div>
      </section>



    </>


  );
};

export default ServicesSection;
