import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";

const Footer = () => {



  const [footerSocial, setFooterSocial] = useState([]);
  const [footerCopyright, setFooterCopyright] = useState([]);

  const [logo, setLogo] = useState([]);

  useEffect(() => {
    fetch(`https://ecommerceleadgenerationservice-4102c662a84f.herokuapp.com/logo`)
      .then((res) => res.json())
      .then((info) => setLogo(info));
  }, []);




  const [footerAddress, setFooterAddress] = useState([]);


  useEffect(() => {
    fetch(`https://ecommerceleadgenerationservice-4102c662a84f.herokuapp.com/footer-address`)
      .then((res) => res.json())
      .then((info) => setFooterAddress(info));
  }, [footerAddress]);

  useEffect(() => {
    fetch(`https://ecommerceleadgenerationservice-4102c662a84f.herokuapp.com/footer-social`)
      .then((res) => res.json())
      .then((info) => setFooterSocial(info));
  }, []);

  useEffect(() => {
    fetch(`https://ecommerceleadgenerationservice-4102c662a84f.herokuapp.com/copyrights`)
      .then((res) => res.json())
      .then((info) => setFooterCopyright(info));
  }, [footerCopyright]);



  const handleSubscriber = (event) => {
    event.preventDefault();
    const email = event.target.email.value;




    const addItem = {
      email,



    };

    const url = `https://ecommerceleadgenerationservice-4102c662a84f.herokuapp.com/add-subscriber`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(addItem),
    })
      .then((res) => res.json())
      .then((result) => {

        alert('Subscribers is Updated');
      });


  }










  return (



    <>

    {
      footerSocial.map(s=><footer className="footer-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-3 col-sm-6 pr-3">
    
            <div className="footer-area-content">
              {
                logo.map(l=> <img src={l.logo} alt="image" /> )
              }
             
              <p>{s.about}</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-5 col-sm-6 ">
            <div className="single-footer-widget">
              <h3>Quick Link</h3>
              <ul className="footer-quick-links ">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="#about-sec">About Us</a>
                </li>
                <li>
                  <a href="#services-sec">Services</a>
                </li>
                <li>
                  <a href="#pricing-sec">Our Pricing</a>
                </li>
                <li>
                  <a href="#contact-sec">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
          
          <div className="col-lg-4 col-md-4 col-sm-6">
            <div className="single-footer-widget">
              <h3>Our Information</h3>
              {
                footerAddress.map(a=> <ul className="footer-quick-links address-link">
                <li>
                  <a href="/">
                    <i className="fa fa-map-marker" />
                    {a.Location}
                  </a>
                </li>
                <li>
                  <a href="/">
                    <i className="fa fa-envelope" />
                    {a.footerEmail}
                  </a>
                </li>
                <li>
                  <a href="/">
                    <i className="fa fa-phone" />
                    {a.Phone}
                  </a>
                </li>
              </ul> )
              }
              
              <ul className="social-list">
                <li>
                  <a href={s.fblink} className="facebook" target="_blank">
                    <i className="fab fa-facebook-f" />
                  </a>
                </li>
               
                <li>
                  <a href={s.inslink} className="instagram" target="_blank">
                    <i className="fab fa-instagram" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="row text-center">
            {
              footerCopyright.map(c=>  <div className="col-lg-12 col-md-12">
              <p>{c.copyrightText}</p>
            </div>)
            }
           
           
          </div>
        </div>
      </div>
    </footer>)
    }





    </>
  );
};

export default Footer;
