import React, { useEffect, useState } from 'react';

const FeaturesServices = () => {

  const [stepwork, setWork] = useState([]);

  useEffect(() => {
    fetch(`https://ecommerceleadgenerationservice-4102c662a84f.herokuapp.com/worksec`)
      .then((res) => res.json())
      .then((info) => setWork(info));
  }, []);

  return (
    

    <>
    {
      stepwork.map(s=> <section className="features-section ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7 col-md-12">
            <div className="features-image">
              <img src={s.processImage} alt="image" />
              <div className="features-shape">
                <img src="assets/img/features/shape.png" className="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="image" />
              </div>
              <div className="features-arrow">
                <img src="assets/img/features/2.png" className="wow animate__animated animate__fadeInRight" data-wow-delay="0.6s" alt="image" />
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-12">
            <div className="features-area-content">
              <span>{s.processText}</span>
              <h2>{s.processHeading}</h2>
              <p>{s.processDetails}</p>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="features-text bg-f4ffb3">
                    <div className="icon">
                      <i className="flaticon-claim" />
                    </div>
                    <h3>{s.pointOne}</h3>
                    <p>{s.pointOneDetails}</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="features-text bg-ffe7dc">
                    <div className="icon">
                      <i className="flaticon-lock" />
                    </div>
                    <h3>{s.pointTwo}</h3>
                    <p>{s.pointTwoDetails}</p>
                  </div>
                </div>
              </div>
              <div className="features-btn">
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> )
    }

   

    </>
  );
};

export default FeaturesServices;