import React, { useEffect, useState } from 'react';

const CounterSection = () => {

  const [countersec, setCounter] = useState([]);

  useEffect(() => {
    fetch(`https://ecommerceleadgenerationservice-4102c662a84f.herokuapp.com/counters`)
      .then((res) => res.json())
      .then((info) => setCounter(info));
  }, []);

  return (


    <>

      {
        countersec.map(c => <section className="fun-facts-area pt-100 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="single-fun-fact">
                  <h3>
                    <span className="odometer" data-count={500}>{c.happyClientsNumber}</span><span className="sign-icon">+</span>
                  </h3>
                  <p>{c.happyClientsText}</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="single-fun-fact">
                  <h3>
                    <span className="odometer" data-count={550}>{c.projectCompleteNumber}</span><span className="sign-icon">+</span>
                  </h3>
                  <p>{c.projectCompleteText}</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="single-fun-fact">
                  <h3>
                    <span className="odometer" data-count={14}>{c.yearofExperienceNumber}</span><span className="sign-icon">+</span>
                  </h3>
                  <p>{c.yearofExperienceText}</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="single-fun-fact">
                  <h3>
                    <span className="odometer" data-count={50}>{c.winingAwardNumber}</span><span className="sign-icon">+</span>
                  </h3>
                  <p>{c.winingAwardText}</p>
                </div>
              </div>
            </div>
          </div>
        </section>)
      }





    </>
  );
};

export default CounterSection;